import React from 'react';
import { Link } from 'react-router-dom';

function Backrooms() {
    return (
        <div>
            <h1 style={{color: "white"}}>Lista poziomów Backrooms</h1>
            <ul>
                {Array.from({ length: 50 }, (_, i) => i + 1).map(level => (
                    <li key={level}>
                        <Link style={{color: "white"}} to={`../backrooms/poziom${level}`}>Poziom {level}</Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Backrooms;
