import React, {useState} from 'react';
import "./backrooms.css"

function BackroomsZero() {
    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [htmlContent, setHtmlContent] = useState('');

    const handlePasswordSubmit = () => {
        setIsAuthenticated(true);
        fetch('https://bot.jbwm.pl/api/zagadka?password='+password)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Błąd sieci');
                }
                return response.text();
            })
            .then(data => setHtmlContent(data))
            .catch(error => {
                console.error('Błąd pobierania danych:', error);
            });
    };

    return (
        <div style={{ width: "100%", marginTop: "20px" }}>
            {isAuthenticated ? (
                <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
            ) : (
                <div style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}>
                    <h2 style={{ color: "white" }}>Wprowadź hasło, aby uzyskać dostęp do poziomu 0</h2>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Wprowadź hasło"
                    />
                    <button onClick={handlePasswordSubmit}>Zatwierdź</button>
                </div>
            )}
        </div>
    );
}

export default BackroomsZero;
