import React, { useState, useEffect } from 'react';
import {useParams} from 'react-router-dom';

function BackroomsLevel() {
    const { level } = useParams();
    const [content, setContent] = useState('');

    useEffect(() => {
        const fetchLevelContent = async () => {
            try {
                const response = await fetch(`/backrooms/poziom${level}.txt`);
                if (!response.ok) throw new Error('Błąd wczytywania pliku');
                const text = await response.text();
                setContent(text);
            } catch (error) {
                setContent('Nie udało się załadować zawartości poziomu.');
            }
        };

        if (level >= 1 && level <= 50) {
            fetchLevelContent();
        } else {
            setContent('Poziom poza zakresem (0-50).');
        }
    }, [level]);

    return (
        <div style={{textAlign: 'center', marginTop: "40px"}}>
            <h2 style={{color: "white", marginBottom: "30px"}}>Poziom {level}</h2>
            <p style={{color: "white", fontSize: "20px", width: "80%", fontFamily: "var(--bs-font-monospace)", margin: "auto", whiteSpace: "pre-line"}}>{content}</p>
        </div>
    );
}

export default BackroomsLevel;
