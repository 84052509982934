import React from "react";
import styles from "../../styles/Rulespage.module.scss";

function Rulespage() {
  return (
    <>
      <div
        className="home_container"
        style={{
          width: "100%",
          padding: 20,
          boxSizing: "border-box",
        }}
      ></div>
      <div class="container mt-5 pt-3" id={styles.container}>
        <div class="text-center mb-5">
          <h1 className="fancy color-white">
            <span>Regulamin</span>
          </h1>
        </div>

        <p></p>
        <h2 className="mt-3">Definicje</h2>
        <p>
          <strong>Jbwm.pl</strong> - serwis społecznościowy oferujący usługi na serwerch gier jbwm.pl, którego właścicielem jest firma SkyNow Artur Paszenda, Borucka 7b, 47-470 Krzanowice, REGON 387273867 NIP 6392020809.
        </p>

        <p>
          <strong>Aplikacja JBWM.PL</strong> - strona internetowa oraz serwery
          gier oparte na rozwiązaniach JBWM.PL
          <br />
          <strong>Minecraft</strong> – Gra sandboxowa wydana przez Mojang AB.
          <br />
          <strong>Gracz</strong> - osoba fizyczna lub prawna, która użytkuje
          serwer gier JBWM.PL
          <br />
          <strong>Klient</strong> - osoba fizyczna lub prawna, która zakupiła
          usługę na stronie sklep.jbwm.pl.
          <br />
          <strong>Usługa</strong> - Przypisanie rangi lub przedmiotu do nazwy
          użytkownika na stronie JBWM.PL
        </p>

        <h2 className="mt-3">Postanowienia ogólne</h2>
        <ol>
          <li>
            Właściciel zastrzega sobie prawo do wprowadzenia zmian w podanym
            regulaminie.
          </li>
          <li>
            Właściciel zastrzega sobie prawo do zmiany cen usług w każdym
            momencie
          </li>
        </ol>

        <h2 className="mt-3">Zasady użytkowania</h2>
        <ol>
          <li>
            Użytkownik użytkując konto na serwerze JBWM.PL akceptuje regulamin
            oraz warunki ogólno przyjętej netykiety poprawnego zachowania w
            internecie.
          </li>
          <li>
            Wszystkie budowle / pluginy / pomysły, które zostały stworzone na
            serwerze / dla serwera lub aktualnie są używane na serwerze są
            własnością serwera i nikomu kto tworzył tą zawartość nie przysługują
            zwroty / rekompensaty.
          </li>
          <li>
            Aby zapewnić wszystkim graczom pozytywne wrażenia z gry i uczciwą
            rywalizację, jakiekolwiek próby oszukiwania systemu serwera JBWM.PL
            na jego niekorzyść, używanie wspomagaczy, obrażanie innych oraz
            reklamowanie innych serwisów oraz serwerów gier może skończyć się
            natychmiastowym wyrzuceniem z serwera bądź natychmiastową blokadą
            konta użytkownika - tzw. banem.
          </li>
          <li>
            W sytuacjach nadzwyczajnych Administracja JBWM.PL zastrzega sobie
            prawo do zbanowania użytkownika lub zablokowania mu korzystania z
            usług serwisu lub serwera do wyjaśnienia sprawy.
          </li>
        </ol>

        <h2 className="mt-3">Rodzaje niedozwolonych działań</h2>

        <p>
          <em>
            Sprecyzowany regulamin niedozwolonych działań znajduje się na
            serwerze gry.
          </em>
        </p>
        <p>
          <strong>Wspomagacze / Cheat</strong> - Używanie niedozwolonych
          modyfikacji pozwalających na oszukiwanie w rozgrywkach.
          <br />
          <strong>Bugowanie / Exploit</strong> - Umyślne używanie
          niedoskonałości gry w celu oszukiwania w rozgrywkach.
          <br />
          <strong>Spam / Flood</strong> - Nadmierne wysyłanie tej samej
          wiadomości lub umieszczanie niechcianych linków oraz przeszkadzanie
          innym użytkownikom w poprawnym odczytywaniu czatu serwerowego.
          <br />
          <strong>Reklamowanie</strong> - Umieszczanie na czacie i w całym
          obrębie serwera i jego strony niechcianych treści reklamowych.
        </p>

        <p>
          W przypadku zostania zbanowanym na serwerze/stronie za
          nieprzestrzeganie netykiety lub naruszenie zasad gry na serwerze,
          klientowi nie przysługuje zwrot kosztów zakupu usług.
        </p>
        <p>
          W przypadku odkrycia osób łamiących regulamin, należy niezwłocznie
          zgłosić ten fakt administracji serwera JBWM.PL.
        </p>

        <h2 className="mt-3">Świadczenie usługi</h2>
        <p>
          Za udany zakup w sklepie, użytkownik otrzymuję rangę lub przedmiot na
          stronie JBWM.PL
          <br />
          Bonusowo użytkownik z tym samym nickiem otrzymuje odpowiednik
          zakupionej usługi na serwerze JBWM.PL Jeżeli przy zakupie nie jest
          napisane na jak długo jest zakupiona usługa (np. na 30dni.) oznacza
          to, że jest przydzielony najdłuższy możliwy czas trwania usługi - do
          końca edycji.
        </p>
        <p>
          Zakupione usługi nie podlegają zwrotowi
          <br />
          Reklamacja PSC, PayPal, Przelew jest możliwa, jeśli na stronie pojawią
          się dane zakupu, kwota zostanie pobrana a usługa nie zostanie
          przydzielona. Reklamacje należy wysyłać na kontakt@otherkey.pl
          <br />
          Właściciel strony i sklepu nie ponosi odpowiedzialności za błędy
          popełnione przez klienta w trakcie użytkowania sklepu.
          <br />
        </p>

        <h2 className="mt-3">Kontakt</h2>
        <p>
          Właściciel strony i sklepu lub firma obsługująca płatności może
          poprosić o udzielenie szczegółowych danych zawartych podczas
          transakcji w celu poprawnego rozpatrzenia problemu.
          <br />
          Rozpatrzenie reklamacji nastąpi niezwłocznie, w czasie nie dłuższym
          niż 7 dni. W przypadku konieczności uzyskania dodatkowych informacji,
          reklamacja może się wydłużyć jednak nie dłużej niż 14 dni od dnia
          otrzymania reklamacji.
          <br />
          <br />W celu skróceniu czasu reklamacji, w treści maila należy
          zamieścić: email, nick z gry, metodę płatności, kwotę płatności,
          potwierdzenie wykonania płatności, nazwę zakupionej usługi.
          <br />
          Dane osobowe są wykorzystywane wyłącznie w celu przeprowadzenia
          płatności za usługę.
        </p>
        <h2 className="mt-3">Postanowienia końcowe</h2>
        <p>
          W przypadku sporu, istnieje możliwość skorzystania z pozasądowego
          rozpatrywania reklamacji w postaci wysłania maila na{" "}
          <strong>kontakt@otherkey.pl</strong>
        </p>
        <p></p>
      </div>
    </>
  );
}

export default Rulespage;
